import React from 'react';
import VideoPlayer from '../components/VideoPlayer';

interface NowVideoProps {}

const NowVideo: React.FC<NowVideoProps> = ({}) => {
  return <VideoPlayer />;
};

export default NowVideo;
