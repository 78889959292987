import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NowVideo from './screens/NowVideo';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/video/:hashedUUID">
          <NowVideo />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
