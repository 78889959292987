import React, {
  createRef,
  RefObject,
  useEffect,
  useState
} from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import RollickLogo from '../assets/whiteLogo.svg';
import PlayIcon from '../assets/playIcon.svg';
import SoundIcon from '../assets/volume.svg';
import MuteIcon from '../assets/mute.svg';
import getOnAndroid from '../assets/getOnAndroid.png';
import getOnIos from '../assets/getOnIos.png';

interface VideoPlayerProps {}

const VideoPlayer: React.FC<VideoPlayerProps> = ({}) => {
  const { hashedUUID } = useParams<{ hashedUUID: string }>();
  const {
    REACT_APP_AZURE_CDN_URL,
    REACT_APP_AZURE_VIDEOS_CONTAINER,
    REACT_APP_AZURE_VIDEO_THUMBNAILS_CONTAINER
  } = process.env;
  const videoUrl = `${REACT_APP_AZURE_CDN_URL}${REACT_APP_AZURE_VIDEOS_CONTAINER}/${hashedUUID}.mp4`;
  const thumbnailUrl = `${REACT_APP_AZURE_CDN_URL}${REACT_APP_AZURE_VIDEO_THUMBNAILS_CONTAINER}/${hashedUUID}.jpg`;
  const videoRef: RefObject<HTMLVideoElement> = createRef();
  const thumbnailRef: RefObject<HTMLImageElement> = createRef();
  const [playing, setPlaying] = useState<boolean>(true);
  const [mute, setMute] = useState<boolean>(true);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [videoContainerWidth, setVideoContainerWidth] = useState<number>();

  const handlePlay = () => {
    if (playing) {
      videoRef.current?.pause();
      setPlaying(false);
    } else {
      videoRef.current?.play();
      setPlaying(true);
    }
  };

  const handleMute = () => {
    setMute(prevMuteValue => !prevMuteValue);
  };

  const handleLoadedData = () => {
    setVideoLoaded(true);
    videoRef.current?.play();
  };

  useEffect(() => {
    if (thumbnailRef.current?.offsetHeight) {
      setVideoContainerWidth(thumbnailRef.current?.offsetHeight! * (9 / 16));
    }
  }, [thumbnailRef.current?.offsetHeight]);

  useEffect(() => {
    videoRef.current?.addEventListener('loadeddata', handleLoadedData);
    return () => {
      videoRef.current?.removeEventListener('loadeddata', handleLoadedData);
    };
  }, [videoRef.current]);

  return (
    <div>
      <a href="https://rollickapp.com" className="logo-container">
        <img src={RollickLogo} className="logo" />
      </a>
      <div className="main-container">
        <div className="middle-container">
          <div
            className="video-container"
            style={{ width: videoContainerWidth || 'auto' }}
          >
            {!videoLoaded && (
              <img
                ref={thumbnailRef}
                src={thumbnailUrl}
                style={{ height: '100%', width: '100%' }}
              />
            )}
            <video
              src={videoUrl}
              ref={videoRef}
              height="100%"
              width={'100%'}
              style={{ display: !videoLoaded ? 'none' : undefined }}
              loop
              autoPlay
              muted={mute}
              className="video-cover"
              playsInline
              preload="none"
            />
            <div className="play-icon-container" onClick={handlePlay}>
              {!playing && <img src={PlayIcon} className="play-icon" />}
            </div>
            <div className="sound-icon-container" onClick={handleMute}>
              <img className="sound-icon" src={mute ? MuteIcon : SoundIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className="download-container">
        <a href="">
          <img src={getOnAndroid} className="download-logo" />
        </a>
        <a href="">
          <img src={getOnIos} className="download-logo" />
        </a>
      </div>
    </div>
  );
};

export default VideoPlayer;
